import React from 'react'
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import Layout from 'components/common/Layout'
import SEO from 'components/common/Seo'
import { TextInput } from '../components/common/TextInput';
import Form from 'components/common/Form'
import MarbleEdgeImg from '../images/marble-edge.png'
export default () => (

  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div >
      <H1Styled>You have been invited to take part in a survey</H1Styled>
      <Form />
      {/* <MarbleEdge src={MarbleEdgeImg} /> */}
    </div>
  </Layout>
)

const H1Styled = styled(D.H1)`
  max-width: 600px;
  font-size: 4.5em;
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  margin-top:1.6em;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 500px) {
    font-size: 27px;
    padding: 10px auto;
    max-width: 90%;
  }
`;

const MarbleEdge = styled.img`
  position: absolute;
  bottom: 0px;
  margin-bottom:0px;
`;